import React from "react";
import { Box, Grid } from "@mui/material";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import HeaderSection from "./HeaderSection";
import SchoolSection from "./SchoolSection";
import QuestionsSection from "../shared/QuestionsSection";
import { ExpertsSliderSection } from "./SliderSection/ExpertsSliderSection";
import { useSchools } from "../../context/SchoolContext";

const stylesComponent = {
  wrapper: {
    margin: "0 auto",
    h2: {
      textAlign: "center",
    },
  },
};

const AppointmentBooking = (): React.ReactElement => {
  const { selectedSchool } = useSchools();
  const isSchoolSlected = selectedSchool?.name;

  return (
    <Box>
      <Grid
        container
        justifyContent="space-around"
        textAlign="center"
        sx={stylesComponent.wrapper}
      >
        <Header />
        <HeaderSection />
        {isSchoolSlected && <ExpertsSliderSection />}
        <Box mt={{ xs: "32px" }}>
          <QuestionsSection
            questionsType={""}
            questionsObjectName={"FAQQuestions.AppointmentBookings"}
          />
        </Box>
        <SchoolSection />
        <Footer />
      </Grid>
    </Box>
  );
};

export default AppointmentBooking;
