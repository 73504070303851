import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const stylesComponent = {
  sectionWrapper: {
    width: "350px",
    backgroundColor: "#FBFBFB",
  },
  learnMoreBtn: {
    "& a": {
      color: "#E60005",
      fontSize: "18px",
      fontWeight: "700",
    },
  },
  icon: {
    color: "#E60005",
    fontSize: "12px",
  },
};

const SchoolSection = (): React.ReactElement => {
  const { t } = useTranslation("base");

  return (
    <Grid item xs={12} pt={6} pb={6} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"600px"}>
          <Box mt={2} mb={2}>
            <Typography variant="h2">
              {t("SchoolSection.0")}
            </Typography>
            <Typography mb={2}>
              {t("SchoolSection.1")}
            </Typography>
          </Box>
          <Typography mt={3} mb={2} sx={stylesComponent.learnMoreBtn}>
            <Link underline="none" href={`/kontakt`} rel="noopener noreferrer">
              {t("SchoolSectionBtn")}
              <ArrowForwardIosIcon sx={stylesComponent.icon} />
            </Link>
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default SchoolSection;
