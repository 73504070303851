import React, { createContext, useContext, useEffect, useState } from "react";
import schoolService from "../services/schoolService";
import { School } from "../models/schoolTypes";
import { schoolIntialValues } from "../models/initialValues";
import { Expert } from "../models/expert";

type SchoolContext = {
  schools: School[];
  experts: Expert[];
  classes: number[];
  selectedSchool?: School;
  selectedClass: number | null;
  zip: string;
  setZip: (value: string) => void;
  setSelectedSchool: (value: any) => void;
  setClass: (value: any) => void;
};

const SchoolsData = createContext<SchoolContext>({
  schools: [],
  experts: [],
  classes: [],
  selectedSchool: schoolIntialValues,
  selectedClass: null,
  zip: "",
  setZip() { },
  setSelectedSchool() { },
  setClass() { },
});

type Props = {
  children: React.ReactNode;
};

const SchoolsProvider = ({ children }: Props): React.ReactElement => {
  const [schools, setSchools] = useState<School[]>([]);
  const [experts, setExperts] = useState<Expert[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<School>();
  const [selectedClass, setClass] = useState<number | null>(null);
  const [zip, setZip] = useState<string>("");
  const [classes, setClasses] = useState<number[]>([]);

  const reset = () => {
    setExperts([]);
    setClass(null);
    setClasses([]);
    setSelectedSchool(schoolIntialValues)
  };

  const getSelectedSchools = () => {
    if (zip.length === 5) {
      schoolService
        .getSchools(zip)
        .then((res: any) => {
          reset();
          setSchools(res.data.data.schools);
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.log(err);
        })
        .finally(() => { });
    }
  };

  useEffect(() => {
    if (selectedSchool && selectedSchool !== schoolIntialValues && selectedClass !== null) {
      setExperts(selectedSchool.experts.filter((e: Expert) => {
        return e.classes.includes(selectedClass);
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass]);

  useEffect(() => {
    if (selectedSchool && selectedSchool.zip !== "") {
      const classes: number[] = [];
      selectedSchool.experts.forEach((e: Expert) => {
        e.classes.forEach((c: number) => { if (!classes.includes(c)) { classes.push(c) } });
      });
      setClasses(classes.sort((n1, n2) => n1 - n2))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  useEffect(() => {
    getSelectedSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip]);

  const providerValue: SchoolContext = {
    schools,
    classes,
    experts,
    selectedSchool,
    selectedClass,
    zip,
    setZip,
    setSelectedSchool,
    setClass,
  };

  return (
    <SchoolsData.Provider value={providerValue}>
      {children}
    </SchoolsData.Provider>
  );
};

const useSchools = (): SchoolContext => useContext<SchoolContext>(SchoolsData);

export default SchoolsProvider;
export { SchoolsData, useSchools };
