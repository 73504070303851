import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StartInfoLabel = () => {
  const { t } = useTranslation("base");
  return (
    <Box
      sx={{
        background: "#E60005",
        color: "white",
        display: "inline",
        width: "100%",
        p: 2,
      }}
    >
      <Typography display="inline" fontSize={"15px"} fontWeight={700}>
        {t("StartInfoLabelBold")}
      </Typography>
      <Typography display="inline" fontSize={"15px"}>
        {t("StartInfoLabel")}
      </Typography>
    </Box>
  );
};

export default StartInfoLabel;
