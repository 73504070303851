import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LinkChevronIcon from "../shared/LinkChevronIcon";
import Question from "../../assets/mood-question 1.png";
import ActionHeart from "../../assets/email-action-heart 1.png";

const stylesComponent = {
  sectionWrapper: {
    padding: "48px 20px 32px",
    backgroundColor: "#EBF5FF",
  },
  sectionContent: {
    fontSize: "16px",
    fontWeight: "500",
    a: {
      color: "#E60005",
      fontWeight: 500,
      fontSize: "16px",
      textDecoration: "none",
    },
  },
  contactUsBtn: {
    "& a": {
      color: "#E60005",
      fontSize: "18px",
      fontWeight: "700",
    },
  },
};

const ContactUs = (): React.ReactElement => {
  const { t } = useTranslation("base");

  return (
    <Grid
      width={"100%"}
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={"start"}
      justifyContent={"center"}
      sx={stylesComponent.sectionWrapper}
      gap={{ md: 12 }}
      mt={6}
    >
      <Box maxWidth={"448px"} p={4}>
        <Box>
          <img src={ActionHeart} alt="DRK" height={48} width={48} />
          <Typography fontSize={"24px"} fontWeight={700} mt={1}>
            {t("ParticipateContactUs.Headline")}
          </Typography>
          <Typography fontSize={"18px"} fontWeight={400} mt={2}>
            {t("ParticipateContactUs.SubText")}
          </Typography>
        </Box>
        <Box mb={{ xs: 2, md: 4 }} sx={stylesComponent.contactUsBtn} mt={2}>
          <Link
            underline="none"
            href={`https://forms.office.com/e/z8y2Ym8Zk7`}
            target="blank"
            rel="noopener noreferrer"
          >
            {t("ParticipateContactUs.FormLinkText")}
            <LinkChevronIcon />
          </Link>
        </Box>
      </Box>

      <Box maxWidth={"448px"} p={4}>
        <Box>
          <img src={Question} alt="DRK" height={48} width={48} />
          <Typography fontSize={"24px"} fontWeight={700} mt={1}>
            {t("HaveQuestions.Headline")}
          </Typography>
          <Typography fontSize={"18px"} fontWeight={400} mt={2}>
            {t("HaveQuestions.SubText")}
          </Typography>
        </Box>

        <Box mb={{ xs: 2, md: 4 }} sx={stylesComponent.contactUsBtn} mt={2}>
          <Link
            underline="none"
            href={`/kontakt`}
            target="blank"
            rel="noopener noreferrer"
          >
            {t("HaveQuestions.FormLinkText")}
            <LinkChevronIcon />
          </Link>
        </Box>
      </Box>
    </Grid>
  );
};

export default ContactUs;
