import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../assets/crossIcon.svg";

const stylesComponent = {
  footerWrapper: {
    backgroundColor: "#333",
    color: "#fff",
    p: { xs: 2, md: "80px 200px" },
  },
  menuItem: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "23px",
    "& a": {
      color: "#fff",
    },
  },
  subMenuItem: {
    fontWeight: "500",
    fontSize: "15px",
    color: "#fff",
    a: {
      marginRight: { md: "16px" },
    }
  },
};

const Footer = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const menuItemsObject = t("Menu", { returnObjects: true });
  const subMenuItemsObject = t("SubMenu", { returnObjects: true });
  const navigationLinksList = [
    "chat-beratung",
    "terminbuchung",
    "ueber-uns",
    "faq",
    "mitmachen",
    // "andere-beratungsangebote", TODO: AndereBeratungen
    "kontakt",
  ];

  const secondaryNavigationLinkList = ["nutzungsbedingungen", "datenschutz", "barrierefreiheit", "impressum"];

  return (
    <Grid item xs={12} sx={stylesComponent.footerWrapper}>
      <Box
        textAlign="left"
        display={"grid"}
        width={{ md: "720px" }}
        gridTemplateColumns={{ md: "repeat(2, 1fr)" }}
        marginTop={"32px"}
      >
        {Object.values(menuItemsObject).map((menuItem, itemIndex) => (
          <Typography
            sx={stylesComponent.menuItem}
            mb={2}
            key={itemIndex.toString()}
          >
            <Link
              underline="none"
              href={`/${navigationLinksList[itemIndex]}`}
              rel="noopener noreferrer"
            >
              {menuItem}
            </Link>
          </Typography>
        ))}
      </Box>

      <Box
        textAlign="left"
        display={{ xs: "grid", md: "none" }}
        flexDirection={"row-reverse"}
        alignItems={"end"}
        justifyContent={"start"}
      >
        <Box
          textAlign="left"
          display={{ xs: "grid" }}
          ml={{ md: 16 }}
          mt="48px"
        >
          {Object.values(subMenuItemsObject).map((menuItem, itemIndex) => (
            <Typography
              mb={{ xs: "12px", md: 0 }}
              key={itemIndex.toString()}
            >
              <Link
                sx={stylesComponent.subMenuItem}
                underline="none"
                href={`/${secondaryNavigationLinkList[itemIndex]}`}
                rel="noopener noreferrer"
                mr={{ md: 2 }}
              >
                {menuItem}
              </Link>
            </Typography>
          ))}
        </Box>
      </Box>
      <Box textAlign="left" mt={{ xs: 8, md: "100px" }}>
        <Link href="https://www.drk.de/" target="_blank"><img src={CrossIcon} alt="DRK" height={55} width={177} /></Link>
        <Box mt={3} mb={{ xs: 5, md: 0 }}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Typography
                sx={stylesComponent.subMenuItem}
              >{t("DRK")}</Typography>
            </Grid>
            <Grid display={{ xs: "none", md: "block", }} item md={9}>
              <Box
                textAlign="left"
                display={{ xs: "none", md: "flex" }}
                flexDirection={"row-reverse"}
                alignItems={"end"}
                justifyContent={"end"}
              >
                <Box
                  textAlign="left"
                  display={{ xs: "flex" }}
                >
                  {Object.values(subMenuItemsObject).map((menuItem, itemIndex) => (
                    <Typography
                      mb={{ xs: 2, md: 0 }}
                      key={itemIndex.toString()}
                    >
                      <Link
                        sx={stylesComponent.subMenuItem}
                        underline="none"
                        href={`/${secondaryNavigationLinkList[itemIndex]}`}
                        rel="noopener noreferrer"
                        mr={{ md: 2 }}
                      >
                        {menuItem}
                      </Link>
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid >
  );
};

export default Footer;
