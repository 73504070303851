export const sourceReferenceForPhotosUsedList = [
  { name: "Quellennachweis für verwendete Fotos" },
  { name: "Javier Sierra, Unsplash" },
  { name: "Tatiana Syrikova, Pexels" },
  { name: "Yan Krukov, Pexels" },
  { name: "Alexander Dummer, Unsplash" },
  { name: "Danielle MacInnes, Unsplash" },
  { name: "Kelly Sikkema / Unsplash" },
  { name: "Kevin Liang, Unsplash" },
  { name: "Polina Tankilevitch, Pexels" },
  { name: "Foto von Pavel Danilyuk von Pexels" },
  { name: "Gabriella Csapo on corelens" },
];

export const contactFormUserTypes = [
  { value: "student", label: "Schulerin / Schüler" },
  { value: "socialWorkers", label: "Sozialarbeitende / Sozialarbeitender" },
  { value: "teacher", label: "Lehrerin / Lehrer" },
  { value: "parent", label: "Elternteil" },
  { value: "others", label: "Sonstiges" },
];

export const contactFormThemas = [
  { value: "val1", label: "ThemaInputOne" },
  { value: "val2", label: "ThemaInputTwo" },
  { value: "val3", label: "ThemaInput3" },
  { value: "val4", label: "ThemaInput4" },
];
