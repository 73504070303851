import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const WhyBecomePartner = (): React.ReactElement => {
  const { t } = useTranslation("base");

  const contentSectionObject = t(
    "WhyBecomePeerPartnerContentSection.ContentList",
    {
      returnObjects: true,
    }
  );
  const contentSectionList = Object.values(contentSectionObject);

  return (
    <Grid
      xs={12}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      mr={{ xs: 2 }}
      ml={{ xs: 2 }}
      mt={6}
    >
      <Box maxWidth={"1200px"}>
        <Typography fontSize={"40px"} fontWeight={700}>
          {t("WhyBecomePeerPartnerContentSection.Header")}
        </Typography>
      </Box>
      <Box
        maxWidth={"1200px"}
        display={"grid"}
        gridTemplateColumns={{ md: "repeat(3, 1fr)" }}
        justifyContent={"center"}
        textAlign={"start"}
        borderRadius={6}
        gap={{ md: 3 }}
        mt={6}
        mb={{ xs: 0, md: "24px" }}
      >
        {contentSectionList.map((listItem, itemIndex) => (
          <Box
            key={itemIndex}
            mb={{ xs: 2, md: 0 }}
            display={"flex"}
            flexDirection={"column"}
            maxWidth={"380px"}
            height={{ md: "290px" }}
            border={"1px solid #D9D9D9"}
            borderRadius={6}
          >
            <Box maxWidth={"314px"} pr={4} pl={4} pt={2} mb={1}>
              <img
                src={process.env.PUBLIC_URL + listItem[0]}
                alt="DRK"
                height={53}
                width={53}
              />
            </Box>
            <Box pr={4} pl={4}>
              <Typography
                fontSize={"20px"}
                fontWeight={700}
                color={"#E60005"}
                mb={2}
              >
                {listItem[1]}
              </Typography>
              <Typography fontSize={"18px"} fontWeight={400} mb={{ xs: 2 }}>
                {listItem[2]}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default WhyBecomePartner;
