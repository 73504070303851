import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const stylesComponent = {
  sectionWrapper: {
    backgroundColor: "#EBF5FF",
    paddingTop: "48px",
    padding: "48px 10px",
  },
  terms: {
    fontSize: "15px",
    fontWeight: "700",
    "& span": {
      fontSize: "15px",
      fontWeight: "500",
    },
  },
  termClose: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#E60005",
  },
  startTermInfo: {
    color: "#E60005",
    fontSize: "15px",
    fontWeight: "700",
    "& span": {
      fontSize: "15px",
      fontWeight: "500",
    },
  },
};

type Props = {
  isChatOpen: boolean;
};

const HeaderSection = ({ isChatOpen }: Props): React.ReactElement => {
  const { t } = useTranslation("base");

  const redirectToLink = (link: string): void => {
    window.location.replace(link);
  };

  return (
    <Grid item xs={12} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"600px"}>
          <Box mt={2}>
            <Typography variant="h1">{t("Header")}</Typography>
          </Box>
          <Box mt={2}>
            <Typography>{t("SubHeader")}</Typography>
          </Box>
          <Box mt={4}>
            <Button
              disabled={isChatOpen === false}
              sx={{ margin: 0 }}
              onClick={() => redirectToLink("https://wa.me/14155238886")}
            >
              {t("Buttons.0")}
              <OpenInNewIcon sx={{ ml: 2 }} />
            </Button>
          </Box>
          <Box mt={2}>
            <Button sx={{ margin: 0 }} disabled={isChatOpen === false}>
              {t("Buttons.1")}
              <OpenInNewIcon sx={{ ml: 2 }} />
            </Button>
          </Box>
          <Box mt={3}>
            {/* remove this message when set chat active after january 2024 */}
            {!isChatOpen && (
              <Typography mb={2} sx={stylesComponent.termClose}>
                {t("Terms.0")}
              </Typography>
            )}
            <Typography sx={stylesComponent.terms}>
              {t("Terms.1")} <span> {t("Terms.2")}</span>
            </Typography>
            {/* <Typography sx={stylesComponent.terms}>
              {t("Terms.3")} <span> {t("Terms.4")}</span>
            </Typography> */}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default HeaderSection;
