import React, { useEffect, useRef, useState } from "react"
import { Box, Grid } from "@mui/material"
import Footer from "../shared/Footer"
import Header from "../shared/Header"
import HeaderSection from "./HeaderSection"
import PeersInfoSection from "./PeersInfoSection"
import JoinPeersSection from "./JoinPeersSection"
import QuestionsSection from "../shared/QuestionsSection"
import StartInfoLabel from "./StartInfoLabel"
import openingHoursService from "../../services/openinigHoursService"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OpeningHours, OpeningHoursState } from "../../models/openingHours"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment from "moment-timezone"

const stylesComponent = {
  wrapper: {
    margin: "0 auto",
  },
}

const ChatAdvice = (): React.ReactElement => {
  // will be used once is chat open

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openingHoursData, setOpeningHoursData] = useState<OpeningHours>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isChatOpen = useRef(false)

  const fetchOpeningHours = () => {
    openingHoursService
      .getOpeningHoursService()
      .then((res: any) => {
        setOpeningHoursData(res.data.data.openingHours)
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
      .finally(() => {})
  }

  useEffect(() => {
    fetchOpeningHours()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // uncomment this when activate chat !!!!!

  // useEffect(() => {
  //   if (openingHoursData) {
  //     isChatOpen.current = isWithinOpeningHours(openingHoursData)
  //   }
  // }, [openingHoursData])

  // const isWithinOpeningHours = (openingHours: OpeningHours) => {
  //   const frankfurtTime = moment.tz("Europe/Berlin")

  //   const dayOfWeek = frankfurtTime.format("dddd")
  //   const currentDay = openingHours[dayOfWeek]

  //   if (currentDay && currentDay.state === OpeningHoursState.CLOSED) {
  //     return false
  //   }

  //   const currentTime = frankfurtTime.format("HH:mm")

  //   const openTime = currentDay.open || "00:00"
  //   const closeTime = currentDay.close || "24:00"

  //   return currentTime >= openTime && currentTime <= closeTime
  // }

  return (
    <Box>
      <Grid
        container
        justifyContent="space-around"
        textAlign="center"
        sx={stylesComponent.wrapper}
      >
        <Header />
        {!isChatOpen.current && <StartInfoLabel />}
        <HeaderSection isChatOpen={isChatOpen.current} />
        <PeersInfoSection />
        <Box mt={{ xs: "48px" }}>
          <QuestionsSection
            questionsType={""}
            questionsObjectName={"FAQQuestions.ChatAdvice"}
          />
        </Box>
        <JoinPeersSection />
        <Footer />
      </Grid>
    </Box>
  )
}

export default ChatAdvice
