import { Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const stylesComponent = {
  sectionWrapper: {
    textAlign: "left",
    padding: "0 20px",
    a: {
      color: "#E60005",
      fontWeight: 500,
      fontSize: "18px",
      textDecoration: "none",
    }
  },
  sectionHeader: {
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "40px",
    mt: 4,
    mb: 2,
  },
  pageHeader: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "40px",
    mt: 4,
    mb: 2,
  },
  sectionContent: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLink: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLabel: {
    fontSize: "22px",
    fontWeight: "700",
    "& span": {
      marginLeft: "5px",
    },
  },
  navigation: {
    fontSize: "15px",
    "& span": {
      color: "#2276D0",
    },
  },
  linkColor: {
    color: "#2276D0",
    fontSize: "18px",
    fontWeight: "700",
  },
  icon: {
    fontSize: "18px",
    marginLeft: "5px",
  },
};

const ImpressumSection = () => {
  const { t } = useTranslation("base");

  return (
    <Grid item xs={12} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"600px"} mt={{ md: "80px" }} mb={{ md: "80px" }}>
          <Box mt={4}>
            <Typography sx={stylesComponent.pageHeader}>
              {t("ImpressumTitle")}
            </Typography>
            <Typography sx={stylesComponent.sectionHeader}>
              {t("LawInfoTitle")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("AdressCompanyTitle")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("AdressStreet")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("AdressCity")}
            </Typography>

            <Typography mt={2} sx={stylesComponent.sectionContent}>
              {t("CompanyRegister")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("LawRegister")}
            </Typography>

            <Typography mt={2} sx={stylesComponent.sectionContentLabel}>
              {t("RepresentedBy")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("RepresentedByContent")}
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography sx={stylesComponent.sectionHeader}>
              {t("ContactLabel")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("MailAdress")}
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography sx={stylesComponent.sectionHeader}>
              {t("ValueAddedTaxLabel")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("ValueAddedTaxContent")}
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography sx={stylesComponent.sectionHeader}>
              {t("DisputeResolutionLabel")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("DisputeResolutionContent")}
            </Typography>
            <Typography sx={stylesComponent.sectionContentLabel}>
              <Link
                href={t("DisputeResolutionLink")}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Typography>{t("DisputeResolutionLink")}</Typography>
              </Link>
            </Typography>
            <Typography sx={stylesComponent.sectionContent}>
              {t("DisputeResolutionAddText")}
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography sx={stylesComponent.sectionHeader}>
              {t("ConsumerDisputeResolutionLabel")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("ConsumerDisputeResolutionContent")}
            </Typography>
            <Typography sx={stylesComponent.sectionContentLabel}>
              <Link
                href={t("ConsumerDisputeResolutionLink")}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("ConsumerDisputeResolutionLink")}
              </Link>
            </Typography>
            <Typography sx={stylesComponent.sectionContent}>
              {t("DisputeResolutionAddText")}
            </Typography>

            <Typography mt={2} sx={stylesComponent.sectionContentLabel}>
              {t("LiabilityForContentsLabel")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("LiabilityForContentsContent")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("LiabilityForContentsContentPt2")}
            </Typography>

            <Typography mt={2} sx={stylesComponent.sectionContentLabel}>
              {t("LiabilityForLinksLabel")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("LiabilityForLinksContent")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("LiabilityForLinksContentPt2")}
            </Typography>

            <Typography mt={2} sx={stylesComponent.sectionContentLabel}>
              {t("CopyrightLabel")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("CopyrightContent")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("CopyrightContentPt2")}
            </Typography>
          </Box>
          {/* 
      <Box mt={4} mb={4}>
        <Typography sx={stylesComponent.sectionHeader}>
          {t("SourceReferenceForPhotosUsedLabel")}
        </Typography>
        {sourceReferenceForPhotosUsedList.map(({ name }, i) => (
          <Typography key={i} mt={2} sx={stylesComponent.sectionContent}>
            {name}
          </Typography>
        ))}
      </Box> */}
        </Box>
      </Box>
    </Grid>
  );
};

export default ImpressumSection;
