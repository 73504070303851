import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSchools } from "../../context/SchoolContext";
import { schoolIntialValues } from "../../models/initialValues";

const stylesComponent = {
  sectionWrapper: {
    backgroundColor: "#EBF5FF",
  },
  select: {
    width: "366px",
    backgroundColor: "#fff",
    margin: "10px 0",
    height: "56px",
    textAlign: "left",
    fontWeight: "700",
    borderRadius: "8px",
    "& fieldset": { border: "none" },
    border: "1px solid #2276D0",
  },
  selectedLabel: {
    color: "#2276D0",
    fontWeight: "bold",
  },
  emptyLabel: {
    color: "#bdbdbd",
    fontWeight: "bold",
  },
  label: {
    position: "static",
    color: "#000",
    display: "inline",
    ":focussed": {
      color: "#000",
    },
  },
  zipInput: {
    alignItems: "center",
    height: "56px",
    width: "366px",
    background: "#FFFFFF",
    mb: 2,
    borderRadius: "8px",
    border: "1px solid #2276D0",
    "& fieldset": { border: "none" },
    fontWeight: "15px",
    "& .MuiInputBase-root": {
      height: "56px",
      fontWeight: "700",
    },
    "& ::-webkit-input-placeholder": {
      color: "#2276D0",
      opacity: 1,
    },
  },
};

const HeaderSection = (): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation("base");

  const {
    zip,
    setZip,
    schools,
    classes,
    selectedSchool,
    setSelectedSchool,
    selectedClass,
    setClass,
  } = useSchools();

  const isZipFilled = zip.length > 4;
  const isSchoolByZip = schools.length > 0;

  const navigateToLink = (link: string): void => {
    window.scrollTo(0, 0);
    navigate(link);
  };

  return (
    <Grid item xs={12} mb={2} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"600px"}>
          <Box mt={2} p={2}>
            <Typography variant="h1">
              {t("TermBooking.0")}
            </Typography>
            <Typography mt={2} mb={2}>
              {t("TermBooking.1")}
            </Typography>
          </Box>
          <Box mb={4}>
            <Box>
              <FormControl>
                <Box>
                  <Typography textAlign={"left"} mb={2} ml={1}>
                    <InputLabel
                      htmlFor="select-zip"
                      id="select-zip-label"
                      sx={stylesComponent.label}
                      focused={false}
                    >
                      {t("WriteSchoolZipLabel")}
                    </InputLabel>
                  </Typography>

                  <TextField
                    name="zipCode"
                    type="number"
                    id="select-zip"
                    placeholder={t("ZipInputPlaceholder")}
                    size="small"
                    fullWidth
                    value={zip}
                    onChange={(e) =>
                      setZip(
                        (e.target.value = e.target.value.slice(0, 5))
                      )
                    }
                    sx={stylesComponent.zipInput}
                  />
                </Box>
              </FormControl>

              {!isSchoolByZip && zip.length > 4 && (
                <Typography color={"#E60005"} fontWeight={800}>
                  {t("NoSchoolByZip")}
                </Typography>
              )}

              {isZipFilled && isSchoolByZip && (
                <>
                  <FormControl>
                    <Typography textAlign={"left"} mb={2} mt={2} ml={1}>
                      <InputLabel
                        htmlFor="select-school"
                        sx={stylesComponent.label}
                        focused={false}
                      >
                        {t("SelectSchoolLabel")}
                      </InputLabel>
                    </Typography>

                    <Select
                      labelId="select-school-label"
                      id="select-school"
                      value={selectedSchool}
                      onChange={(e) => setSelectedSchool(e.target.value)}
                      sx={stylesComponent.select}
                      IconComponent={ExpandMoreIcon}
                    >
                      {schools?.map((school: any, i: number) => (
                        <MenuItem key={i} value={school}>
                          {school.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {classes.length !== 0 && (
                    <FormControl>
                      <Typography textAlign={"left"} ml={1} mt={2}>
                        <InputLabel
                          htmlFor="select-school-class"
                          sx={stylesComponent.label}
                          focused={false}
                        >
                          {t("SelectClassLabel")}
                        </InputLabel>
                      </Typography>
                      <Select
                        labelId="select-school-class-label"
                        id="select-school-class"
                        value={selectedClass || ""}
                        onChange={(e) => setClass(e.target.value)}
                        sx={stylesComponent.select}
                        IconComponent={ExpandMoreIcon}
                      >
                        {classes.map((item: any) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </>
              )}
            </Box>

            <Button
              disabled={
                !(selectedSchool !== schoolIntialValues && selectedClass !== null)
              }
              onClick={() => navigateToLink("/expertenauswahl")}
            >
              {t("ButtonLabel")}
            </Button>
          </Box>
          <Typography fontSize={"15px"} p={4} pt={0} color={"#E60005"}>
            <Box component="span" fontWeight={700}>
              {t("BetaVersionWarninigTextHighlighted")}
            </Box>
            {t("BetaVersionWarninigText")}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default HeaderSection;
